import * as React from "react";
import Layout from "../components/layout";

const About = () => {
  return (
    <Layout pageTitle="About">
      <p>I guess I'm the about page</p>
    </Layout>
  );
};

export default About;
